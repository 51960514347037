import React, {useEffect, useRef, useState} from 'react';
import {useGlobal} from "reactn";
import {useParams, useHistory} from "react-router-dom";
import "./Contact.sass";
import {FiCalendar, FiMessageCircle, FiStar, FiUsers, FiSearch, FiPlusCircle} from 'react-icons/fi';
import {useToasts} from "react-toast-notifications";
import {useDispatch, useSelector} from "react-redux";
import Actions from "../../constants/Actions";
import upload from "../../actions/uploadImage";
import Config from "../../config";
import {FiEdit2} from "react-icons/fi";
import {getContact, createContact, updateContact, deleteContact, searchContacts} from "../../actions/contacts";
import changePicture from "../../actions/changePicture";
import TopBar from "./components/TopBar";
import Popup from "./components/Popup";
import PhoneNumber from "./components/PhoneNumber";
import Formatter from '../../lib/formatter';

const Input = ({icon, placeholder, type, onChange, required, value}) => {
    return (
        <div className="uk-margin-small-top">
            <div className="uk-inline uk-width-1-1">
                <span className="uk-form-icon uk-form-icon-flip" data-uk-icon={`icon: ${icon}`}
                      onChange={onChange}/>
                <input className="uk-input uk-margin-remove" required={required} placeholder={placeholder}
                       value={value} type={type} onChange={onChange}/>
            </div>
        </div>
    );
};

const Contact = () => {
    const { addToast } = useToasts();
    const { id } = useParams();
    const setOver = useGlobal('over')[1];
    const dispatch = useDispatch();
    const history = useHistory();
    const [nav, setNav] = useGlobal('nav');

    const type = (id == 'new' ? 'create' : 'edit');
    const [errors, setErrors] = useState(null);
    const [popup, setPopup] = useState(false);

    const emptyContact = {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      phoneNumbers: []
    }

    const [contact, setContact] = useState(emptyContact);
    const fileInput = useRef(null);

    const back = () => {
        setOver(false);
        history.replace('/');
    }

    useEffect(() => {
      if(nav != 'contacts') { setNav('contacts') }
      if(id == 'new') { setContact(emptyContact) }
      else {
        getContact(id).then(res => { setContact(res.data.contact) });
      }
    }, [id, setContact]);

    const numbersOnly = str => { return str.replace(/\D/g,'') }

    const onPhoneNumberChange = e => {
      let index = e.target.dataset.index;
      console.log(contact, index);
      let value = numbersOnly(e.target.value);
      let newContact = { ...contact };
      newContact.phoneNumbers[index].number = value;
      setContact(newContact);
    }

    const onPhoneNameChange = e => {
      let index = e.target.dataset.index;
      let value = e.target.value;
      let newContact = { ...contact };
      newContact.phoneNumbers[index].name = value;
      setContact(newContact);
    }

    const addPhoneNumber = (e) => {
      e.preventDefault();
      let newContact = { ...contact };

      newContact.phoneNumbers.push({name:'Mobile', index:newContact.phoneNumbers.length, number:''});
      setContact(newContact);
    }

    const removePhoneNumber = (e) => {
      e.preventDefault();
      let index = e.target.dataset.index;
      let newContact = { ...contact };
      newContact.phoneNumbers.splice(index, 1);

      setContact(newContact);
    }

    const onDeleteContact = (e) => {
      e.preventDefault();
      setPopup(true);
    }

    const phoneNumberList = contact.phoneNumbers && contact.phoneNumbers.map((phoneNumber, index) => {
      return (<PhoneNumber key={index} index={index} name={phoneNumber.name} number={(phoneNumber.number.length > 10 ? phoneNumber.number.substr(1) : phoneNumber.number)}
        onNumberChange={onPhoneNumberChange} onNameChange={onPhoneNameChange} onRemove={removePhoneNumber} />);
    });

    const okToast = content => {
        addToast(content, {
            appearance: 'success',
            autoDismiss: true,
        })
    };

    const errorToast = content => {
        addToast(content, {
            appearance: 'error',
            autoDismiss: true,
        })
    };

    const getTitle = () => {
      let fullName = `${contact.firstName} ${contact.lastName}`;

      switch (type) {
          case 'create':
              return 'Create user';
          case 'edit':
              return 'Edit ' + fullName.substr(0, 16) + (fullName.length > 16 ? '...' : '');
          default:
              return 'Delete ' + fullName.substr(0, 16) + (fullName.length > 16 ? '...' : '');
      }
    };

    const createC = async e => {
        e.preventDefault();
        try {
            let newContact = await createContact(contact);
            history.replace(`/contact/${newContact.data._id}`);
            okToast(`Contact has been created`);
        }
        catch (e) {
            if (e && e.response) setErrors(e.response.data);
            errorToast('Failed to create a contact');
        }
    };

    const updateC = async e => {
        e.preventDefault();
        try {
            let updateRes = await updateContact(contact);
            setContact(updateRes.data);
            searchContacts().then(res => dispatch({type: Actions.SET_CONTACTS, contacts: res.data.contacts})).catch(err => console.log(err));
            okToast('Contact has been edited');
        }
        catch (e) {
            if (e && e.response) setErrors(e.response.data);
            errorToast('Failed to edit a contact');
        }
    };

    const deleteC = async (email, username) => {
      //TODO Only allow account admins
        try {
            await deleteContact(contact._id);
            history.replace('/contact/new');
            // setContact(emptyContact);
            okToast('Contact has been deleted');
        }
        catch (e) {
            errorToast('Failed to delete contact');
        }
    };


    const change = async image => {
        const picture = await upload(image, null, () => {}, 'square');
        // await changePicture(picture.data.image._id);
        const newContact = {...contact, picture: picture.data.image};
        localStorage.setItem('contact', JSON.stringify(newContact));
        await setContact(newContact);
    };

    const notAvailable = name => {
        addToast(`The ${name} feature is not available yet.`, {
            appearance: 'warning',
            autoDismiss: true,
        })
    };

    const Picture = () => {
        if (contact.picture)
            return <img src={`${Config.url || ''}/api/images/${contact.picture.shieldedID}/256`} alt="Picture" className="picture"/>;
        else
            return <div className="img">{contact.firstName && contact.firstName.substr(0,1)}{contact.lastName && contact.lastName.substr(0,1)}</div>;
    };

    return (
        <div className="contact uk-flex uk-flex-column uk-flex-between">
            <TopBar back={back} contactId={contact._id} onDeleteContact={onDeleteContact} />
            <input
                className="file-input"
                type="file"
                ref={fileInput}
                accept="image/*"
                onChange={e => change(e.target.files[0])}
            />
            <div className="picture settings uk-margin-small" onClick={() => fileInput && fileInput.current && fileInput.current.click()}>
                <Picture/>
                <div className="overlay">
                    <div className="text"><FiEdit2/></div>
                </div>
            </div>

            <div className="data-editor" hidden={!['create', 'edit'].includes(type)}>
                <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle admin-delete">
                    <form className="uk-flex uk-flex-column uk-flex-center uk-flex-middle"
                          onSubmit={e => type === 'edit' ? updateC(e) : createC(e)}>

                        <Input icon="pencil" placeholder="First Name" type="text" required={true}
                               value={contact.firstName} onChange={e => setContact({...contact, firstName:e.target.value})}/>
                        {errors && errors.firstName &&
                        <div className="admin-form-error">{errors.firstName}</div>}

                        <Input icon="pencil" placeholder="Last Name" type="text" required={true}
                               value={contact.lastName} onChange={e => setContact({...contact, lastName:e.target.value})}/>
                        {errors && errors.lastName &&
                        <div className="admin-form-error">{errors.lastName}</div>}

                        <Input icon="mail" placeholder="Email" type="email" required={false} value={contact.email}
                        onChange={e => setContact({...contact, email:e.target.value})}/>
                        {errors && errors.email &&
                          <div className="admin-form-error">{errors.email}</div>}

                        <Input icon="pencil" placeholder="Company" type="text" required={false}
                               value={contact.company} onChange={e => setContact({...contact, company:e.target.value})}/>
                        {errors && errors.company &&
                        <div className="admin-form-error">{errors.company}</div>}

                        <div className="phone-numbers">
                          {phoneNumberList}
                        </div>

                        <button onClick={addPhoneNumber} className="uk-button uk-button-primary uk-margin-top">
                          <FiPlusCircle /> Add a phone number
                        </button>

                        <button type="submit" style={{marginBottom: 4}}
                                className="uk-button uk-button-primary uk-margin-top">{type === 'edit' ? 'Update' : 'Create'} Contact
                        </button>
                    </form>
                    <div className="padding"/>
                </div>
            </div>

            <div className={popup ? 'uk-visible':'uk-invisible'}>
              <Popup deleteContact={deleteC} onClose={() => setPopup(false)}/>
            </div>

        </div>
    );
}

export default Contact;
