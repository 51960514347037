import RoomUtil from "./roomUtil";

function isPhoneNumber(number) {
  if( (typeof number === "object" || typeof number === 'function') && (number !== null) ) {
    return false;
  }

  return number.match(/^(\+?)(1?)(\d{3})(\d{3})(\d{4})$/);
}

function getE164Number(number) {
  if(!isPhoneNumber(number)) return false;

  var cleaned = ('' + number).replace(/\D/g, '')
  var match = cleaned.match(/^(\+?)(1?)(\d{3})(\d{3})(\d{4})$/)
  return '1' + match[3] + match[4] + match[5];
}

function formatPhoneNumber(number) {
  var cleaned = ('' + number).replace(/\D/g, '')
  var match = cleaned.match(/^(\+?)(1?)(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[3] + ')' + match[4] + '-' + match[5]
  }
  return number;
}

function getRoomTitle(userId, room) {
  let other = {};

  if(room.people && room.people.length > 0) {
      room.people.forEach(person => {
        if (userId !== person._id) other = person;
    });
  }

  if (!other.firstName) {
      other = { ...other, firstName: 'Deleted', lastName: 'User' };
  }

  var title = (room.isGroup ? room.title : `${other.firstName} ${other.lastName}`);

  if(RoomUtil.isExternalRoom(room)) {
    if(room.title && room.title.length > 0 ) { title = room.title }
    else {
      title = '';
      room.phoneNumbers.forEach(number => {
        title += `, ${formatPhoneNumber(number)}`
      });
      title = title.substr(2);
    }
  }
  title = title.substr(0, 22);

  return {title, other};
}

export default {
  getRoomTitle,
  formatPhoneNumber,
  isPhoneNumber,
  getE164Number
}
