import axios from "axios";
import Config from "../config";

export const getContact = id => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/contact/get",
        data: { id }
    });
};

export const searchContacts = (text, limit) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/contacts/search",
        data: { limit: limit || 30, search: text || '' }
    });
};


export const createContact = contact => {
  return axios({
      method: "post",
      url: (Config.url || '') + "/api/contact/create",
      data: { contact }
  });
};

export const updateContact = contact => {
  return axios({
      method: "post",
      url: (Config.url || '') + "/api/contact/update",
      data: { contact }
  });
}

export const deleteContact = (id) => {
  return axios({
      method: "post",
      url: (Config.url || '') + "/api/contact/delete",
      data: { id }
  });
}
