import React, {useEffect, useState} from 'react';
import {useGlobal} from "reactn";
import "./Panel.sass";
import TopBar from "./components/TopBar";
import SearchBar from "./components/SearchBar";
import NavBar from "./components/NavBar";
import MeetingBar from "./components/MeetingBar";
import Room from "./components/Room";
import Meeting from "./components/Meeting";
import User from "./components/User";
import Contact from "./components/Contact";
import createRoom from "../../actions/createRoom";
import getRooms from "../../actions/getRooms";
import search from "../../actions/search";
import {searchContacts} from "../../actions/contacts";
import getFavorites from "../../actions/getFavorites";
import getMeetings from "../../actions/getMeetings";
import {useDispatch, useSelector} from "react-redux";
import Actions from "../../constants/Actions";
import Settings from "./components/Settings";
import {useHistory, useLocation} from "react-router-dom";
import Formatter from "../../lib/formatter";

const Panel = () => {
    const [nav, setNav] = useGlobal('nav');
    const token = useGlobal('token')[0];
    const searchText = useGlobal('search')[0];
    const rooms = useSelector(state => state.io.rooms);
    const contacts = useSelector(state => state.io.contacts);
    const [searchResults, setSearchResults] = useGlobal('searchResults');
    const [favorites, setFavorites] = useGlobal('favorites');
    const [meetings, setMeetings] = useGlobal('meetings');
    const [callStatus, setCallStatus] = useGlobal('callStatus');
    const [over, setOver] = useGlobal('over');
    const refreshMeetings = useSelector(state => state.io.refreshMeetings);

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        getRooms().then(res => dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms})).catch(err => console.log(err));
        // search().then(res => setSearchResults(res.data.users)).catch(err => console.log(err));
        search().then(res => setSearchResults({rooms: res.data.rooms, users: res.data.users})).catch(err => console.log(err));
        getFavorites().then(res => setFavorites(res.data.favorites)).catch(err => console.log(err));
        getMeetings().then(res => setMeetings(res.data.meetings)).catch(err => console.log(err));
        searchContacts().then(res => dispatch({type: Actions.SET_CONTACTS, contacts: res.data.contacts})).catch(err => console.log(err));
    }, [setSearchResults, setFavorites]);

    const CreateNewContactBtn = () => {
      return (
        <button class="uk-button uk-button-large create-button"
        onClick={() => {
          const target = '/contact/new';
          if (location.pathname !== target) history.replace(target);
        }}>Create New</button>
      );
    }

    useEffect(() => {
        getMeetings().then(res => setMeetings(res.data.meetings)).catch(err => console.log(err));
    }, [refreshMeetings]);

    const roomsList = rooms.map(room => <Room key={room._id} room={room} />);
    // const searchResultsList = searchResults.map(user => <User key={user._id} user={user} />);
    const favoritesList = favorites.map(room => <Room key={room._id} room={room} />);
    const meetingsList = meetings.map(meeting => <Meeting key={meeting._id} meeting={meeting} />);
    const contactsList = [(<CreateNewContactBtn key='new' />)].concat(contacts && contacts.map(contact => <Contact key={contact._id} contact={contact} />));
    const searchResultsList = () => {
      let searchList = [];
      if(searchResults.users) searchResults.users.forEach(user => searchList.push(<User key={user._id} user={user} />));
      if(searchResults.rooms) searchResults.rooms.forEach(room => searchList.push(<Room key={room._id} room={room} />));

      if(searchList.length > 0) { return searchList }
      else {
        if(Formatter.isPhoneNumber(searchText)) {
          return (
            <button className="uk-button uk-button-primary uk-button-small create-button" onClick={() => newConvo([Formatter.getE164Number(searchText)])}>
              Start a new conversation with {Formatter.formatPhoneNumber(searchText)}
            </button>
          );
        } else {
          return (<Notice text={`There are no search results for "${searchText}"`} />)
        }
      }
    };

    const Notice = ({text}) => <div className="notice">{text}</div>

    const newConvo = async (phoneNumbers) => {

      const res = await createRoom(null, phoneNumbers);
      setNav('rooms');
      const target = `/room/${res.data.room._id}`;
      dispatch({type: Actions.SET_ROOM, room: res.data.room});
      dispatch({type: Actions.SET_MESSAGES, messages: res.data.room.messages});
      if (location.pathname !== target) history.replace(target);
    };
    return (
        <div className="panel">
            <TopBar/>
            <SearchBar/>
            <NavBar/>
            {callStatus === 'in-call' && (!location.pathname.startsWith('/meeting') || over === false) && <MeetingBar/>}
            <div className="rooms">
                {nav === 'rooms' && roomsList}
                {nav === 'rooms' && rooms.length === 0 && <Notice text="There are no rooms yet. Contact someone or create a group to begin!" />}
                {nav === 'search' && searchResultsList()}
                {nav === 'favorites' && favoritesList}
                {nav === 'favorites' && favorites.length === 0 && <Notice text="There are no favorites yet. Add a room to your favorites!" />}
                {nav === 'meetings' && meetingsList}
                {nav === 'meetings' && favorites.length === 0 && <Notice text="There are no meetings yet. Create a new meeting or call someone!" />}
                {nav === 'settings' && <Settings/>}
                {nav === 'contacts' && contactsList}
                {nav === 'contacts' && contacts.length === 0 && <Notice text="There are no contacts yet. Please add them here!" />}
            </div>
        </div>
    );
}

export default Panel;
