import React, {useState} from 'react';
import "./Contact.sass";
import {FiPhone, FiMoreHorizontal} from 'react-icons/fi';
import Picture from "../../../components/Picture";
import moment from "moment";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const Contact = ({contact}) => {
    const [hover, setHover] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const title = (`${contact.firstName} ${contact.lastName}`).substr(0, 22);
    const width = window.innerWidth;
    const isMobile = width < 700;

    return (
        <div
            className="contact uk-flex"
            onMouseOver={!isMobile ? () => setHover(true) : undefined}
            onMouseOut={!isMobile ? () => setHover(false) : undefined}
            onClick={() => {
                const target = `/contact/${contact._id}`;
                if (location.pathname !== target) history.replace(target);
            }}>
            <div className="uk-flex uk-flex-middle">
                <div className="profile">
                    <Picture user={contact} group="false" picture={contact.picture} title={contact.title} />
                </div>
            </div>
            <div className="text">
                <div className="title">{title}{title.length > 22 && '...'}</div>
                <div className="message">{contact.company && contact.company.substr(0, 26)}{contact.company && contact.company.length > 26 && '...'}</div>
            </div>
        </div>
    );
}

export default Contact;
