import React from 'react';
import "./Popup.sass";
import {FiX} from "react-icons/fi";

const DeleteConfirm = ({deleteContact, onClose}) => {

    return (
        <div className="contact-popup">
            <div className="box">
                <div className="top-controls">
                    <div className="title">Confirm Contact Delete</div>
                    <div className="close"><FiX onClick={onClose}/></div>
                </div>

                <div className="data-editor">
                    <div className="uk-flex uk-flex-column uk-flex-center uk-flex-middle admin-delete">
                        <div className="uk-text-center">Are you sure you want to delete contact?</div>
                        <button className="uk-button uk-button-danger uk-margin-top" style={{marginBottom: 4}}
                          onClick={e => { deleteContact(e); onClose(); }}>Delete Contact
                        </button>
                        <button className="uk-button uk-button-secondary"
                              onClick={onClose}>Cancel
                        </button>
                        <div className="padding"/>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default DeleteConfirm;
