import React, {useEffect, useRef, useState} from 'react';
import {useGlobal} from "reactn";
import {useDispatch} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import createRoom from "../../../actions/createRoom";
import Actions from "../../../constants/Actions";
import InputMask from 'react-input-mask';
import {FiTrash2, FiMessageSquare} from 'react-icons/fi';
import "./PhoneNumber.sass";

const PhoneNumber = ({index, name, number, onNumberChange, onNameChange, onRemove}) => {
  const [nav, setNav] = useGlobal('nav');
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const chat = async () => {
      const res = await createRoom(number);
      setNav('rooms');
      const target = `/room/${res.data.room._id}`;
      dispatch({type: Actions.SET_ROOM, room: res.data.room});
      dispatch({type: Actions.SET_MESSAGES, messages: res.data.room.messages});
      if (location.pathname !== target) history.replace(target);
  };

  return (
    <div className="contact-phone-number uk-margin-small-top">
        <div className="uk-inline uk-width-1-1">
          <select className="uk-select" data-index={index} value={name} onChange={onNameChange}>
            <option value="Mobile">Mobile</option>
            <option value="Office">Office</option>
            <option value="Home">Home</option>
            <option value="Fax">Fax</option>
            <option value="Other">Other</option>
          </select>
          <InputMask className="uk-input uk-margin-remove" mask="(999)999-9999" data-index={index} value={number} onChange={onNumberChange} />
          &nbsp;<FiTrash2 color="red" data-index={index} onClick={onRemove} />
          &nbsp;{ (name === 'Mobile' || name === 'Office')  && <FiMessageSquare onClick={chat} /> }
        </div>
    </div>
  )
}

export default PhoneNumber;
