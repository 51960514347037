import React from 'react';
import {useGlobal} from "reactn";
import "./NavBar.sass";
import {FiCalendar, FiMessageCircle, FiStar, FiUsers, FiSearch, FiBookOpen} from 'react-icons/fi';
import {useToasts} from "react-toast-notifications";

const NavBar = () => {
    const [nav, setNav] = useGlobal('nav');

    return (
        <div className="nav-bar uk-flex">
            <div className={`item${nav === 'rooms' ? ' active' : ''}`} onClick={() => setNav('rooms')}>
                <div className="icon">
                    <FiMessageCircle/>
                </div>
                <div className="text">Rooms</div>
            </div>
            <div className={`item${nav === 'search' ? ' active' : ''}`} onClick={() => setNav('search')}>
                <div className="icon">
                    <FiSearch/>
                </div>
                <div className="text">Search</div>
            </div>
            <div className={`item${nav === 'favorites' ? ' active' : ''}`} onClick={() => setNav('favorites')}>
                <div className="icon">
                    <FiStar/>
                </div>
                <div className="text">Favorites</div>
            </div>
            {/*
            <div className={`item${nav === 'meetings' ? ' active' : ''}`} onClick={() => {
                setNav('meetings');
            }}>
                <div className="icon">
                    <FiUsers/>
                </div>
                <div className="text">Meetings</div>
            </div>
            */}
            <div className={`item${nav === 'contacts' ? ' active' : ''}`} onClick={() => setNav('contacts')}>
                <div className="icon">
                    <FiBookOpen/>
                </div>
                <div className="text">Contacts</div>
            </div>
        </div>
    );
}

export default NavBar;
